import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCurrentUserRoles } from 'store/users/usersSelectors';

import { ACCESS_DENIED } from 'constants/routingConstants';

import { ADMIN } from 'constants/userRoles';
const PrivateRoute = ({
  component: Component,
  requiredRoles,
  excludeAdmin,
  currentUserRoles,
  ...rest
}) => {
  const hasRoles =
    requiredRoles && currentUserRoles.length > 0
      ? currentUserRoles.some(role => requiredRoles.includes(role))
      : excludeAdmin
      ? !(currentUserRoles.length === 1 && currentUserRoles.includes(ADMIN))
      : true;
  if (hasRoles) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: ACCESS_DENIED,
          state: { accessDenied: true }
        }}
      />
    );
  }
};

const mapStateToProps = state => ({
  currentUserRoles: getCurrentUserRoles(state)
});

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
  excludeAdmin: PropTypes.bool,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string)
};

export default connect(mapStateToProps)(PrivateRoute);
