export const STATUS_DRAFT = 1;
export const STATUS_ASSIGNMENT = 2;
export const STATUS_PRE_ASSESSMENT = 3;
export const STATUS_ASSESSMENT = 4;
export const STATUS_PENDING_DECISION = 5;
export const STATUS_DELETED = 6;
export const STATUS_DROP = 7;
export const STATUS_PATENT = 8;
export const STATUS_KEEP_SECRET = 9;
export const STATUS_PUBLISH = 10;
export const STATUS_ON_HOLD = 11;
export const STATUS_PROPOSE_DELETE = 12;

export const decisionStatuses = [
  STATUS_DELETED,
  STATUS_DROP,
  STATUS_PATENT,
  STATUS_KEEP_SECRET,
  STATUS_PUBLISH,
  STATUS_ON_HOLD
];

export const statuses = t => [
  { id: STATUS_DRAFT, name: t('draft') },
  { id: STATUS_ASSIGNMENT, name: t('assignment') },
  { id: STATUS_PRE_ASSESSMENT, name: t('preassessment') },
  { id: STATUS_ASSESSMENT, name: t('assessment') },
  { id: STATUS_PENDING_DECISION, name: t('pendingDecision') },
  { id: STATUS_DROP, name: t('drop') },
  { id: STATUS_PATENT, name: t('patent') },
  { id: STATUS_KEEP_SECRET, name: t('keepSecret') },
  { id: STATUS_PUBLISH, name: t('publish') },
  { id: STATUS_ON_HOLD, name: t('onHold') }
];

export const inventionStatusesOptions = t => [
  { value: STATUS_DRAFT, label: t('draft') },
  { value: STATUS_ASSIGNMENT, label: t('assignment') },
  { value: STATUS_PRE_ASSESSMENT, label: t('preassessment') },
  { value: STATUS_ASSESSMENT, label: t('assessment') },
  { value: STATUS_PENDING_DECISION, label: t('pendingDecision') }
];

export const decisionStatusesOptions = t => [
  { value: STATUS_DROP, label: t('drop') },
  { value: STATUS_PATENT, label: t('patent') },
  { value: STATUS_KEEP_SECRET, label: t('keepSecret') },
  { value: STATUS_PUBLISH, label: t('publish') },
  { value: STATUS_ON_HOLD, label: t('onHold') }
];
