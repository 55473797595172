import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { t } from 'helpers/i18n';
import JsFileDownloader from 'js-file-downloader';

import { EXPORT_TO_EXCEL, fetchInventions } from 'actions/inventionsActions';
import { setAreFiltersApplied } from 'actions/filterAndSearchActions';

import {
  getFilterAndSearchValues,
  getHasAnyFiltersSaved
} from 'store/filterAndSearch/filterAndSearchSelectors';

import {
  getInventionsSortingOrder,
  getIsInventionsSortingApplied
} from 'store/inventions/inventionsSelectors';

import { getOrganisationId } from 'store/organisation/organisationSelectors';
import { getCurrentUserId } from 'store/users/usersSelectors';

import {
  getOrganisationInventions,
  getUnassignedInventions,
  getMyPreAssessments,
  getMyAssessments,
  getAllAssessments,
  getKnowledgeDatabase
} from 'helpers/axios/axiosRequests';

import {
  checkForStatusFiltering,
  getExportApiUrl,
  getExportFilename
} from 'helpers/exportHelpers';

import { getToken } from 'helpers/cookies';

import {
  INVENTIONS_PAGE,
  NOT_ASSIGNED,
  MY_PRE_ASSESSMENTS,
  MY_ASSESSMENTS,
  ALL_ASSESSMENTS,
  KNOWLEDGE_DATABASE
} from 'constants/routingConstants';

const convertArrayToString = (sort, order) => {
  let string = '';
  if (sort) {
    // eslint-disable-next-line array-callback-return
    sort.map(el => {
      const substring = `&order[${el}]=${order}`;
      string = string.concat(substring);
    });
  }
  return string;
};

const prepareParams = (payload, filters, skipStatus) => {
  let params = '';

  if (payload) {
    if (payload.page) {
      params = params + `&page=${payload.page}`;
    }
    if (payload.itemsPerPage) {
      params = params + `&itemsPerPage=${payload.itemsPerPage}`;
    }
    if (payload.sort) {
      params =
        params +
        `${
          typeof payload.sort.field === 'string'
            ? `&order[${payload.sort.field}]=${payload.sort.order}`
            : convertArrayToString(payload.sort.field, payload.sort.order)
        }`;
    }
  }

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value && key.slice(-4) !== 'Type') {
        const excludeFilters = ['Date', 'deadline', 'serial', 'name', 'id'];
        if (excludeFilters.some(type => key.includes(type))) {
          params = params + `&${key}=${encodeURIComponent(value.trim())}`;
        } else {
          for (let i = 0; i < value.length; i++) {
            const searchParam = value[i].value ? value[i].value : value[i];

            if (skipStatus && key === 'status[]') {
              continue;
            }

            params =
              params +
              `&${key}=${encodeURIComponent(
                typeof searchParam === 'string'
                  ? searchParam.trim()
                  : searchParam
              )}`;
          }
        }
      }
    });
  }

  return params;
};

function* fetchInventionsSaga({ payload }) {
  try {
    const organisationId = yield select(getOrganisationId);
    const currentUserId = yield select(getCurrentUserId);
    const filters = yield select(getFilterAndSearchValues);
    const hasStatusFiltersApplied = checkForStatusFiltering(filters);

    const params = prepareParams(payload, filters);

    let data;

    switch (payload.type) {
      case INVENTIONS_PAGE:
        data = yield call(
          getOrganisationInventions,
          organisationId,
          params,
          hasStatusFiltersApplied
        );
        break;
      case NOT_ASSIGNED:
        data = yield call(getUnassignedInventions, params);
        break;
      case MY_PRE_ASSESSMENTS:
        data = yield call(getMyPreAssessments, {
          currentUserId,
          params
        });
        break;
      case MY_ASSESSMENTS:
        data = yield call(getMyAssessments, {
          currentUserId,
          params
        });
        break;
      case ALL_ASSESSMENTS:
        data = yield call(getAllAssessments, '?' + params.substring(1));
        break;
      case KNOWLEDGE_DATABASE:
        data = yield call(
          getKnowledgeDatabase,
          organisationId,
          params,
          hasStatusFiltersApplied
        );
        break;
      default:
        break;
    }

    if (Object.values(filters).reduce((acc, el) => acc || el, false)) {
      yield put(setAreFiltersApplied(true));
    } else {
      yield put(setAreFiltersApplied(false));
    }

    yield put(
      fetchInventions.success({
        data: data.data.data,
        pagination: data.data.meta
      })
    );
  } catch (error) {
    yield put(fetchInventions.failure());
    yield toast.error(t('getInventionsFailure'));
    console.error(error);
  }
}

function* exportToExcelSaga({ exportLocation }) {
  try {
    const currentUserId = yield select(getCurrentUserId);
    const isSortingApplied = yield select(getIsInventionsSortingApplied);
    const sort = isSortingApplied
      ? yield select(getInventionsSortingOrder)
      : null;

    const hasFiltersApplied = yield select(getHasAnyFiltersSaved);
    const filters = hasFiltersApplied
      ? yield select(getFilterAndSearchValues)
      : null;

    const hasStatusFiltersApplied = checkForStatusFiltering(filters);
    const filterSortParams = prepareParams(
      { sort },
      filters,
      hasStatusFiltersApplied
    );

    const exportFilename = getExportFilename(exportLocation);
    const exportApiUrl = getExportApiUrl(
      currentUserId,
      exportLocation,
      filterSortParams,
      filters
    );

    yield toast.success(t('exportToXlsxHasBeenQueued'));

    yield new JsFileDownloader({
      url: exportApiUrl,
      method: 'GET',
      headers: [
        {
          name: 'Accept',
          value: 'application/vnd.ms-excel'
        },
        {
          name: 'Authorization',
          value: `Bearer ${getToken()}`
        }
      ],
      filename: exportFilename
    });
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
    yield console.error(error);
  }
}

export default function* formTypeSaga() {
  yield all([
    takeEvery(fetchInventions.TRIGGER, fetchInventionsSaga),
    takeEvery(EXPORT_TO_EXCEL, exportToExcelSaga)
  ]);
}
