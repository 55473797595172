import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { showNewInventionTypeChooseModal } from 'actions/newInventionTypeChooseModalActions';
import { clearAllFields } from 'actions/filterAndSearchActions';
import { setShowSearchAndFilterSection } from 'actions/layoutDetailsActions';

import {
  fetchAssessmentsCounter,
  fetchPreassessmentsCounter,
  fetchUnassignedIdeasCounter
} from 'actions/inventionAssessmentActions';

import { getCurrentUserRoles } from 'store/users/usersSelectors';

import {
  getCurrentInventionStatus,
  getIsInventionBeingCreated,
  getIsDecisionMakerBeingAssigned,
  getIsReviewManagerBeingAssigned,
  getIsPreAssessmentBeingDone
} from 'store/singleInvention/singleInventionSelectors';

import {
  getIsExpertAssigning,
  getAssessment,
  getAssessmentsCounter,
  getPreassessmentsCounter,
  getUnassignedIdeasCounter
} from 'store/inventionAssessments/inventionAssessmentsSelectors';

import NotificationCounter from 'Atoms/NotificationCounter';
import ISvgWrapper from 'Atoms/ISvgWrapper';

import {
  AddNewInventionIcon,
  AssessmentFormsIcon,
  AssessmentsIcon,
  InventionFormsIcon,
  InventionsIcon,
  KnowledgeDatabaseIcon,
  UnassignedInventionsIcon,
  YourAssessmentsIcon,
  YourPreAssessmentsIcon,
  PlatformIcon
} from 'assets/menuIcons';

import {
  INVENTIONS_PAGE,
  INVENTION_TYPE,
  ASSESSMENT_TYPE,
  NOT_ASSIGNED,
  MY_PRE_ASSESSMENTS,
  MY_ASSESSMENTS,
  ALL_ASSESSMENTS,
  KNOWLEDGE_DATABASE
} from 'constants/routingConstants';

import {
  INVENTOR,
  REVIEW_MANAGER,
  REVIEW_COORDINATOR,
  EXPERT,
  ADMIN,
  DECISION_MAKER
} from 'constants/userRoles';

import { ItemWrapper, MenuItem, MenuLabel, ProSidebarWrapper } from './styles';
import usePrevious from 'helpers/hooks/usePrevious';

const LeftMenu = ({
  shouldBeCollapsed,
  showNewInventionTypeChooseModal,
  userRoles,
  history,
  hideMenu,
  clearAllFields,
  showSearchAndFilter,
  currentInventionStatus,
  isInventionBeingCreated,
  isReviewManagerBeingAssigned,
  isDecisionMakerBeingAssigned,
  isExpertAssigning,
  singleAssessment,
  assessmentsCounter,
  preassessmentsCounter,
  unassignedIdeasCounter,
  fetchAssessmentsCounter,
  fetchPreassessmentsCounter,
  fetchUnassignedIdeasCounter
}) => {
  const { t } = useTranslation();

  const previousIsInventionBeingCreated = usePrevious(isInventionBeingCreated);
  const previousIsReviewManagerBeingAssigned = usePrevious(
    isReviewManagerBeingAssigned
  );
  const previousIsDecisionMakerBeingAssigned = usePrevious(
    isDecisionMakerBeingAssigned
  );
  const previousIsExpertAssigning = usePrevious(isExpertAssigning);
  const previousCurrentInventionStatus = usePrevious(currentInventionStatus);
  const previousSingleAssessment = usePrevious(singleAssessment);

  useEffect(() => {
    fetchUnassignedIdeasCounter('?status=2');
    fetchPreassessmentsCounter('?status=3');
    fetchAssessmentsCounter();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (previousIsInventionBeingCreated) {
      fetchUnassignedIdeasCounter('?status=2');
    }

    if (previousCurrentInventionStatus < 3) {
      if (
        previousIsDecisionMakerBeingAssigned ||
        previousIsReviewManagerBeingAssigned
      ) {
        fetchUnassignedIdeasCounter('?status=2');
        fetchPreassessmentsCounter('?status=3');
      }
    }

    if (currentInventionStatus === 4 && previousCurrentInventionStatus === 3) {
      fetchPreassessmentsCounter('?status=3');
    }

    if (
      (currentInventionStatus === 4 && previousIsExpertAssigning) ||
      singleAssessment?.decision_proposal_status !==
        previousSingleAssessment?.decision_proposal_status
    ) {
      fetchAssessmentsCounter();
    }
    // eslint-disable-next-line
  }, [
    isInventionBeingCreated,
    currentInventionStatus,
    isReviewManagerBeingAssigned,
    isDecisionMakerBeingAssigned,
    isExpertAssigning,
    singleAssessment
  ]);

  const menuItems = [
    {
      title: t('addNewInvention'),
      icon: AddNewInventionIcon,
      onClick: showNewInventionTypeChooseModal,
      shouldBeDisplayed: userRoles.includes(INVENTOR)
    },
    {
      title: userRoles.includes(INVENTOR)
        ? t('yourInventions')
        : t('inventions'),
      icon: InventionsIcon,
      url: INVENTIONS_PAGE,
      shouldBeDisplayed: !userRoles.every(e => [EXPERT, ADMIN].includes(e))
    },
    {
      title: t('unassignedInventions'),
      icon: UnassignedInventionsIcon,
      url: NOT_ASSIGNED,
      shouldBeDisplayed: userRoles.includes(REVIEW_COORDINATOR),
      itemCounter: unassignedIdeasCounter
    },
    {
      title: t('yourPreassessments'),
      icon: YourPreAssessmentsIcon,
      url: MY_PRE_ASSESSMENTS,
      shouldBeDisplayed: userRoles.includes(REVIEW_MANAGER),
      itemCounter: preassessmentsCounter
    },
    {
      title: t('yourAssessments'),
      icon: YourAssessmentsIcon,
      url: MY_ASSESSMENTS,
      shouldBeDisplayed: userRoles.includes(EXPERT),
      itemCounter: assessmentsCounter
    },
    {
      title: t('allAssessments'),
      icon: AssessmentsIcon,
      url: ALL_ASSESSMENTS,
      shouldBeDisplayed: userRoles.some(e =>
        [REVIEW_MANAGER, DECISION_MAKER].includes(e)
      )
    },
    {
      title: t('knowledgeDatabase'),
      icon: KnowledgeDatabaseIcon,
      url: KNOWLEDGE_DATABASE,
      shouldBeDisplayed: !(userRoles.length === 1 && userRoles.includes(ADMIN))
    },
    {
      title: t('inventionType'),
      icon: InventionFormsIcon,
      url: INVENTION_TYPE,
      shouldBeDisplayed: userRoles.includes(ADMIN)
    },
    {
      title: t('assessmentType'),
      icon: AssessmentFormsIcon,
      url: ASSESSMENT_TYPE,
      shouldBeDisplayed: userRoles.includes(ADMIN)
    },
    {
      title: t('platform'),
      icon: PlatformIcon,
      url: 'https://patents.iamip.com'
    }
  ];

  const handleHideMenu = () => {
    clearAllFields();
    showSearchAndFilter(false);
    localStorage.removeItem('previousPage');
    localStorage.removeItem('previousSort');
    hideMenu && hideMenu();
  };

  const handleOnClick = onClick => {
    onClick();
    handleHideMenu();
  };

  const prepareMenuItem = menuItem => {
    if (
      Object.keys(menuItem).includes('shouldBeDisplayed') &&
      !menuItem.shouldBeDisplayed
    ) {
      return false;
    } else {
      const item = onClick => (
        <MenuItem
          isCollapsed={shouldBeCollapsed}
          active={history.location.pathname === menuItem.url}
          onClick={() => (onClick && handleOnClick(onClick)) || undefined}
        >
          {menuItem.itemCounter > 0 && (
            <NotificationCounter
              notificationNumber={menuItem.itemCounter}
              left={shouldBeCollapsed ? 'calc(50% + 22px)' : 'calc(23%)'}
              top="2px"
            />
          )}
          <ISvgWrapper src={menuItem.icon} width={30} height={30} />
          {!shouldBeCollapsed && <MenuLabel>{menuItem.title}</MenuLabel>}
        </MenuItem>
      );

      if (menuItem.onClick) {
        return item(menuItem.onClick);
      }

      return (
        <Link
          to={
            menuItem.url.includes('https')
              ? { pathname: menuItem.url }
              : menuItem.url
          }
          target={(menuItem.url.includes('https') && '_blank') || ''}
          onClick={() => {
            if (history.location.pathname !== menuItem.url) {
              handleHideMenu(menuItem);
            }
          }}
        >
          {item()}
        </Link>
      );
    }
  };

  return (
    <ProSidebarWrapper>
      {menuItems.map((menuItem, index) => (
        <ItemWrapper key={`${menuItem.title}-${index}`}>
          {shouldBeCollapsed ? (
            <Tooltip
              content={menuItem.title}
              direction="right"
              useDefaultStyles
            >
              {prepareMenuItem(menuItem)}
            </Tooltip>
          ) : (
            prepareMenuItem(menuItem)
          )}
        </ItemWrapper>
      ))}
    </ProSidebarWrapper>
  );
};

LeftMenu.propTypes = {
  shouldBeCollapsed: PropTypes.bool,
  location: PropTypes.object,
  userRoles: PropTypes.array,
  history: PropTypes.object,
  hideMenu: PropTypes.func,
  clearAllFields: PropTypes.func,
  showSearchAndFilter: PropTypes.func,
  currentInventionStatus: PropTypes.number,
  isInventionBeingCreated: PropTypes.bool,
  isReviewManagerBeingAssigned: PropTypes.bool,
  isDecisionMakerBeingAssigned: PropTypes.bool,
  isExpertAssigning: PropTypes.bool,
  singleAssessment: PropTypes.object,
  assessmentsCounter: PropTypes.number,
  preassessmentsCounter: PropTypes.number,
  unassignedIdeasCounter: PropTypes.number
};

const mapStateToProps = state => ({
  userRoles: getCurrentUserRoles(state),
  currentInventionStatus: getCurrentInventionStatus(state),
  isInventionBeingCreated: getIsInventionBeingCreated(state),
  isReviewManagerBeingAssigned: getIsReviewManagerBeingAssigned(state),
  isDecisionMakerBeingAssigned: getIsDecisionMakerBeingAssigned(state),
  isPreAssessmentBeingDone: getIsPreAssessmentBeingDone(state),
  isExpertAssigning: getIsExpertAssigning(state),
  singleAssessment: getAssessment(state),
  assessmentsCounter: getAssessmentsCounter(state),
  preassessmentsCounter: getPreassessmentsCounter(state),
  unassignedIdeasCounter: getUnassignedIdeasCounter(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showNewInventionTypeChooseModal,
      clearAllFields,
      showSearchAndFilter: setShowSearchAndFilterSection,
      fetchAssessmentsCounter,
      fetchPreassessmentsCounter,
      fetchUnassignedIdeasCounter
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeftMenu));
