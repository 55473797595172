import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PrivateRoute from 'Elements/PrivateRoute';
import ErrorBoundaryHOC from 'HOC/ErrorBoundaryHOC/ErrorBoundaryHOC';
import MainLayout from 'Layouts/MainLayout';

import {
  ACCESS_DENIED,
  ASSESSMENT_TYPE,
  ASSESSMENTS_PAGE,
  CREATE_INVENTION,
  HOME_PAGE,
  INVENTION_TYPE,
  INVENTIONS_PAGE,
  LOGIN_PAGE,
  NOT_FOUND,
  NOT_ASSIGNED,
  MY_PRE_ASSESSMENTS,
  MY_ASSESSMENTS,
  ALL_ASSESSMENTS,
  KNOWLEDGE_DATABASE,
  PDF_PAGE
} from 'constants/routingConstants';
import {
  ADMIN,
  INVENTOR,
  EXPERT,
  REVIEW_MANAGER,
  REVIEW_COORDINATOR,
  DECISION_MAKER
} from 'constants/userRoles';

import HomePage from 'Pages/HomePage';
import ErrorPage from 'Pages/ErrorPage';
import ListPage from 'Pages/ListsPage';
import InventionTypeCreatorPage from 'Pages/InventionTypeCreatorPage';
import AssessmentTypeCreatorPage from 'Pages/AssessmentTypeCreatorPage';
import LoginPage from 'Pages/LoginPage/LoginPage';
import CreateInventionPageContainer from 'Pages/CreateInventionPage/CreateInventionPageContainer';
import SingleInventionPage from 'Pages/SingleInventionPage/SingleInventionPage';
import InventionTypeListPage from 'Pages/InventionTypeListPage/InventionTypeListPage';
import AssessmentTypeListPage from 'Pages/AssessmentTypeListPage/AssessmentTypeListPage';
import InventionAssessmentsListPage from 'Pages/InventionAssessmentsListPage/InventionAssessmentsListPage';
import SingleAssessmentPage from 'Pages/SingleAssessmentPage';
import DownloadPDFPage from 'Pages/DownloadPDFPage';

const Routes = () => (
  <Route
    render={props => (
      <MainLayout {...props}>
        <Switch>
          <Route path={LOGIN_PAGE} component={LoginPage} />
          <PrivateRoute
            exact
            path={[HOME_PAGE, '/']}
            component={ErrorBoundaryHOC(HomePage)}
          />
          <PrivateRoute
            exact
            path={`${INVENTION_TYPE}/:id`}
            component={ErrorBoundaryHOC(InventionTypeCreatorPage)}
            requiredRoles={[ADMIN]}
          />
          <PrivateRoute
            exact
            path={`${ASSESSMENT_TYPE}/:id`}
            component={ErrorBoundaryHOC(AssessmentTypeCreatorPage)}
            requiredRoles={[ADMIN]}
          />
          <PrivateRoute
            exact
            path={INVENTIONS_PAGE}
            component={ErrorBoundaryHOC(ListPage)}
            requiredRoles={[
              INVENTOR,
              REVIEW_COORDINATOR,
              REVIEW_MANAGER,
              DECISION_MAKER
            ]}
          />
          <PrivateRoute
            exact
            path={NOT_ASSIGNED}
            component={ErrorBoundaryHOC(ListPage)}
            requiredRoles={[REVIEW_COORDINATOR]}
          />
          <PrivateRoute
            exact
            path={MY_ASSESSMENTS}
            component={ErrorBoundaryHOC(ListPage)}
            requiredRoles={[EXPERT]}
          />
          <PrivateRoute
            exact
            path={MY_PRE_ASSESSMENTS}
            component={ErrorBoundaryHOC(ListPage)}
            requiredRoles={[REVIEW_MANAGER]}
          />
          <PrivateRoute
            exact
            path={ALL_ASSESSMENTS}
            component={ErrorBoundaryHOC(ListPage)}
            requiredRoles={[REVIEW_MANAGER, DECISION_MAKER]}
          />
          <PrivateRoute
            exact
            path={KNOWLEDGE_DATABASE}
            component={ErrorBoundaryHOC(ListPage)}
            excludeAdmin
          />
          <PrivateRoute
            exact
            path={`${INVENTIONS_PAGE}/:id`}
            component={ErrorBoundaryHOC(SingleInventionPage)}
            excludeAdmin
          />
          <PrivateRoute
            exact
            path={CREATE_INVENTION}
            component={ErrorBoundaryHOC(CreateInventionPageContainer)}
            requiredRoles={[INVENTOR]}
          />
          <PrivateRoute
            exact
            path={`${INVENTIONS_PAGE}/:id/edit`}
            component={ErrorBoundaryHOC(CreateInventionPageContainer)}
            requiredRoles={[INVENTOR]}
          />
          <PrivateRoute
            exact
            path={INVENTION_TYPE}
            component={ErrorBoundaryHOC(InventionTypeListPage)}
            requiredRoles={[ADMIN]}
          />
          <PrivateRoute
            exact
            path={ASSESSMENT_TYPE}
            component={ErrorBoundaryHOC(AssessmentTypeListPage)}
            requiredRoles={[ADMIN]}
          />
          <PrivateRoute
            exact
            path={`${INVENTIONS_PAGE}/:id${ASSESSMENTS_PAGE}`}
            component={ErrorBoundaryHOC(InventionAssessmentsListPage)}
            requiredRoles={[
              REVIEW_COORDINATOR,
              REVIEW_MANAGER,
              EXPERT,
              DECISION_MAKER
            ]}
          />
          <PrivateRoute
            exact
            path={`${INVENTIONS_PAGE}/:inventionId${ASSESSMENTS_PAGE}/:id`}
            component={ErrorBoundaryHOC(SingleAssessmentPage)}
            requiredRoles={[EXPERT, REVIEW_MANAGER, DECISION_MAKER]}
          />
          <PrivateRoute
            exact
            path={`${INVENTIONS_PAGE}/:inventionId${PDF_PAGE}/:id`}
            component={ErrorBoundaryHOC(DownloadPDFPage)}
            requiredRoles={[
              INVENTOR,
              REVIEW_MANAGER,
              DECISION_MAKER,
              REVIEW_COORDINATOR
            ]}
          />
          <Route exact path={ACCESS_DENIED} component={ErrorPage} />
          <Route path={NOT_FOUND} component={ErrorPage} />
          <Redirect from="*" to={NOT_FOUND} />
        </Switch>
      </MainLayout>
    )}
  />
);

export default Routes;
