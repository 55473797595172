import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getIsTabletSize } from 'store/layoutDetails/layoutDetailsSelectors';

import {
  STATUS_DRAFT,
  STATUS_ASSIGNMENT,
  STATUS_PRE_ASSESSMENT,
  STATUS_ASSESSMENT,
  STATUS_PENDING_DECISION,
  decisionStatuses,
  statuses
} from 'constants/inventionStatuses';

import { Timeline, Status, Dot } from './styles';

const StatusTimeline = ({ status, isTabletSize }) => {
  const { t } = useTranslation();

  const prepareName = el => {
    if (el === STATUS_PENDING_DECISION) {
      return t('awaitingDecision');
    }
    if (!decisionStatuses.includes(el)) {
      if (isNaN(el)) {
        return el;
      }
      return statuses(t).find(e => e.id === el).name;
    }
    return t('pendingAction');
  };

  // TODO: for now status closed is not handled and never will be active, change when backend will be ready
  const renderStatuses = () => {
    const data = [
      STATUS_DRAFT,
      STATUS_ASSIGNMENT,
      STATUS_PRE_ASSESSMENT,
      STATUS_ASSESSMENT,
      STATUS_PENDING_DECISION,
      decisionStatuses.includes(status) ? status : null,
      t('closed')
    ];

    return data.map((el, index) => (
      <Status key={index} active={status === el}>
        <Dot active={status === el} />
        {el ? prepareName(el) : t('pendingAction')}
      </Status>
    ));
  };

  return (
    <Fragment>
      {isTabletSize ? (
        <Status active>
          <Dot active />
          {prepareName(status)}
        </Status>
      ) : (
        renderStatuses()
      )}
      <Timeline isTabletSize={isTabletSize} />
    </Fragment>
  );
};

StatusTimeline.propTypes = {
  isTabletSize: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

const mapStateToProps = state => ({
  isTabletSize: getIsTabletSize(state)
});

export default connect(mapStateToProps)(StatusTimeline);
