import React from 'react';
import { useTranslation } from 'react-i18next';
import TablePageLayout from 'Elements/TablePageLayout';
import Tooltip from 'react-tooltip-lite';

import Button from 'Atoms/Button';

import { assessmentStatuses } from 'constants/assessmentStatuses';

import { INVENTIONS_PAGE, ASSESSMENTS_PAGE } from 'constants/routingConstants';
import { useHistory } from 'react-router-dom';
import env from 'helpers/env';

import { formatDate } from 'helpers/dateHelpers';
import { isInteger } from 'lodash';
import { EXPERT } from 'constants/userRoles';

const AssessmentsList = ({
  data,
  pagination,
  isLoading,
  handleChangePage,
  onSortClick,
  limit,
  onLimitSelect,
  isAllAssessments,
  currentUserId,
  currentUserRoles
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const columnNames = {
    id: t('assessmentId'),
    inventionId: t('idNumber'),
    inventionTitle: t('title'),
    figure: t('representativeFigure'),
    assessmentInventors: t('inventor'),
    expert: t('expertName'),
    assessmentType: t('assessmentType'),
    status: t('status'),
    deadline: t('deadline'),
    actions: t('actions')
  };

  const renderButton = (shouldDisableButton, newPath) => (
    <Button
      colorVariant="blue"
      disabled={shouldDisableButton}
      value={t('preview')}
      small
      onClick={() =>
        history.push({
          pathname: newPath,
          state: {
            previousPage: history.location.pathname
          }
        })
      }
    />
  );

  const prepareActions = (el, inventionId, assessmentId) => {
    const shouldDisableButton =
      isAllAssessments &&
      !currentUserRoles.includes(EXPERT) &&
      el.expert?.data.id === currentUserId;
    const newPath = `${INVENTIONS_PAGE}/${inventionId}${ASSESSMENTS_PAGE}/${assessmentId}`;

    return shouldDisableButton ? (
      <Tooltip
        arrow={false}
        className="tooltipClass"
        content={t('expertRightsDisabled')}
        direction="left-end"
        useDefaultStyles
      >
        {renderButton(shouldDisableButton, newPath)}
      </Tooltip>
    ) : (
      renderButton(shouldDisableButton, newPath)
    );
  };

  const rows = data
    ? data.map(el => {
        const id = !isInteger(el.id)
          ? el && el.id && el.id.split('/').pop()
          : el.id;
        const inventionId = el.invention?.data?.id.split('/').pop() ?? '';
        return {
          id: id,
          inventionId:
            el.invention?.data.attributes.serial_number || inventionId,
          title: el.invention?.data.attributes.name,
          figure: () => (
            <>
              {el.invention?.data.attributes.figure_url ? (
                <img
                  src={`${env('API_URL')}${
                    el.invention?.data.attributes.figure_url
                  }`}
                  alt=""
                />
              ) : (
                '-'
              )}
            </>
          ),
          name: `${el.invention?.data.attributes.inventor.data.attributes.first_name} ${el.invention?.data.attributes.inventor.data.attributes.last_name}`,
          expert: `${el.expert?.data.attributes.first_name} ${el.expert?.data.attributes.last_name}`,
          assessmentType: el.survey?.data.attributes.form.data.attributes.name,
          status: assessmentStatuses(t).find(status => status.id === el.status)
            ?.label,
          deadline: formatDate(el?.deadline),
          actions: () => prepareActions(el, inventionId, id)
        };
      })
    : [];

  return (
    <>
      <TablePageLayout
        columnNames={columnNames}
        data={rows}
        formTypeListMeta={pagination}
        handleChangePage={handleChangePage}
        isFetching={isLoading}
        limit={limit}
        onLimitSelect={onLimitSelect}
        onSortClick={onSortClick}
      />
    </>
  );
};

export default AssessmentsList;
